import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpService) { }

  async getChangeRequestCountApi(payload: any) {
    return await this.http.post('/student/getChangeRequestCount', payload);
  } 

  async getPlacedCountUserWise(payload: any) {
    return await this.http.post('/drives/getPlacedCountUserWise', payload);
  }

  async getStudentStatusApi(payload: any) {
    return await this.http.post('/student/getStudentStatus', payload);
  } 

  async fetchCampusDetails(group_account_id: string) {
    const fetchCampusDetails =
      await this.http.get(
        `/student/fetchCampusDetails/${group_account_id}`
      );
    return fetchCampusDetails;
  }

  async studentDriveCount(payload: any){
    return await this.http.post('/drives/studentStatusCount', payload);
  }

  async studentDriveData(payload: any){
    return await this.http.post('/drives/fetchStudentWiseDriveCount', payload);
  }

  async singleInvite(body: any) {
    const singleInvite =
      await this.http.post(
        '/student/invite',
        body
      );
    return singleInvite;
  }

  async fetchDepartmentDegreeSpecialization(account_id: any) {
    const fetchDepartmentDegreeSpecialization =
      await this.http.get(
        `/student/fetchDepartmentDegreeSpecialization/${account_id}`,
      );
    return fetchDepartmentDegreeSpecialization;
  }

  async fetchProgrammeMapping(body: any) {
    const fetchProgrammeMapping =
      await this.http.post(
        '/programmeMapping/fetch',
        body
      );
    return fetchProgrammeMapping;
  }

  async fetchStudentDetails(body: any) {
    const fetchStudentDetails =
      await this.http.post(
        `/student/fetchStudentData`,
        body
      );
    return fetchStudentDetails;
  }

  async downloadStudentData(body: any) {
    const downloadStudentData =
      await this.http.post(
        `/student/downloadStudentData`,
        body
      );
    return downloadStudentData;
  }

  async fetchChangeRequest(body: any) {
    const fetchChangeReq =
      await this.http.post(
        `/studentFields/fetchChangedFields`,
        body
      );
    return fetchChangeReq;
  }

  async fetchProgrammeDetails() {
    const fetchProgrammeDetails =
      await this.http.get(
        '/programmeMapping/fetchProgramme',
      );
    return fetchProgrammeDetails;
  }

  async bulkUpdate(payload: any) {
    const result = await this.http.post('/student/bulkUpdate', payload);
    return result;
  }

  async bulkInvite(group_account_id: string, payload: any) {
    const result = await this.http.post(`/student/bulkInvite/${group_account_id}`, payload);
    return result;
  }

  async signedUrl(payload: any) {
    const result = await this.http.post('/student/signedUrl', payload);
    return result;
  }

  async downloadTemplate(group_account_id: string) {
    return await this.http.get(`/student/downloadTemplate/${group_account_id}`);
  }

  async resetPassword(payload: any) {
    return await this.http.post('/student/resetPassword', payload);
  }

  async sendActivationLink(payload: any) {
    return await this.http.post('/student/sendActivationLink', payload);
  }

  async changeAction(payload: any) {
    return await  this.http.post('/student/changeAction', payload);
  }

  async deleteStudent(payload: any) {
    return await this.http.post('/student/deleteStudent', payload);
  }

  async setCustomPassword(payoad: any) {
    return await this.http.post('/student/setCustomPassword', payoad);
  }

  async editProfile(payload: any) {
    return await this.http.post('/student/editProfile', payload);
  }

  async auditInfo(payload: any) {
    return await this.http.post(`/student/getAudit`, payload);
  };

  async fetchSpecificStudentData(payload: any) {
    return await this.http.post(`/student/fetchSpecificStudentData`, payload);
  }

  async fetchStudentProfile(payload: any) {
      return await this.http.post(`/student/profile`, payload);
  }

  async changeUserEmail(payload: any) {
    return await this.http.put(`/student/email`, payload);
  }

  async changeUserMobile(payload: any) {
    return await this.http.put(`/student/mobile`, payload);
  }

  async downloadStudentResume(payload: any) {
    return await this.http.post(`/student/downloadStudentResume`, payload);
  }

  async fetchChangedFieldsByUserId(body: any) {
    const fetchChangeReqData = await this.http.post(
      `/studentFields/fetchChangedFieldsByUserId`,
      body
    );
    return fetchChangeReqData;
  }

  async fetchChangeRequestStudent(body: any) {
    const fetchChangeReq = await this.http.post(
      `/studentFields/fetchChangeRequestStudent`,
      body
    );
    return fetchChangeReq;
  }

  async downloadStudentRequest(body: any) {
    try {
      const downloadRequests = await this.http.post(
        `/studentFields/downloadStudentRequest`,
        body
      );
      return downloadRequests;
    } catch (error) {
      console.error("Error downloading student request:", error);  
      throw new Error("Failed to download student request");
    }
  }

  async postChangeRequestDetails(body: any) {
    const postChangeRequest = await this.http.post(
      `/student/changeStudentRequest`,
      body
    );
    return postChangeRequest;
  }

  async changeStudentRequestBulk(payload: any) {
    const response = await this.http.post(
      '/student/changeStudentRequestBulk',
      payload
    );

    return response;
  }
}
