<div *ngIf="isLoading || isDataLoading" class="{{(isLoading || isDataLoading) ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div class="drive-email-container" *ngIf="!isDataLoading">
    <form [formGroup]="templateFormGroup">
        <div class="template-container">
            <div class="multi-container"  *ngFor="let each of driveTemplate">
                <div class="p-float-label create-fields">
                    <p-dropdown 
                        placeholder="Select Template" 
                        [options]="allEmailTemplates[each.value]"
                        optionLabel="label" 
                        optionValue="value"
                        [formControlName]="each.value"
                        [autoDisplayFirst]="false"
                    ></p-dropdown>
                    <label for="inputtext">{{ each.label| driveLabelPipe}}</label>
                </div>
            </div>
        </div>
    </form>
</div>