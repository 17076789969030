import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService, HttpService } from '@services';
import { driveTemplates } from '../../../core/enums/index';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-drive-email-template',
  templateUrl: './drive-email-template.component.html',
  styleUrls: ['./drive-email-template.component.css']
})
export class DriveEmailTemplateComponent implements OnInit {
  @Input() email_templates:any = {};
  @Input() allTemplates:any = {};
  @Output() updateEmailTemplete: EventEmitter<any> = new EventEmitter();
  group_account_id: string = '';
  isLoading: boolean = false;
  isDataLoading: boolean = false;
  templateFormGroup: FormGroup = new FormGroup({});
  driveTemplate = driveTemplates;
  allEmailTemplates: any = {};

  constructor(
        public httpService: HttpService, 
        public globalService: GlobalService,
        public messageService: MessageService
      ) { }

  async ngOnInit() {
    let accountData = this.globalService.getLocalStorage('account_details')!;
    this.group_account_id = JSON.parse(accountData).group_account_id;
    this.isDataLoading = true;
    await this.fetchEmailTemplates();
    this.isDataLoading = false;
  }

  async fetchEmailTemplates() {
    const response = await this.globalService.fetchAllTemplate({
      template_type: 'driveTemplates',
      category: 'all',
      fields: ['id', 'template_title', 'category', 'isDefault', 'active']
    });

    if (!response.success) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error in fetching email templates' });
      return
    };

    this.allTemplates = response.data.reduce((acc: any, template: any) => {
      if (!acc[template.category]) {
        acc[template.category] = [];
      }
      acc[template.category].push(template);
      return acc;
    }, {});

    this.createEmailTemplateForm();
  }

  createEmailTemplateForm() {

    for (let each of this.driveTemplate) {
      let templateKey = each.value;
      this.allEmailTemplates[templateKey] = [];
      let defaultId = null;
      let selectedTemplate = null;

      this.allTemplates[templateKey]?.forEach((template: any) => {
        if (!template.active) return;

        this.allEmailTemplates[templateKey].push({
          label: template.template_title,
          value: template.id
        });

        if (template.isDefault) {
          defaultId = template.id;
        }

        if (this.email_templates[templateKey] == template.id) {
          selectedTemplate = template.id;
        }
      });

      const emailTemplateId = selectedTemplate || defaultId;
      this.templateFormGroup.addControl(templateKey, new FormControl(emailTemplateId, Validators.required));
    }
  }

  async saveEmailTemplates(drive_id: string) {
    if(this.templateFormGroup.invalid) {
      return { message: 'Please select all email templates', status: false };
    }

    this.isLoading = true;
    
    let payload = {
      drive_id,
      email_templates: this.templateFormGroup.value
    }

    let res = await this.httpService.put(`/drives/${drive_id}/templates`, payload);
    this.isLoading = false;
    this.updateEmailTemplete.emit(this.templateFormGroup.value);

    if(res.data) {
      return { message: 'Email templates saved successfully', status: true };
    } 

    return { message: 'Failed to save email templates', status: false };
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }
}
