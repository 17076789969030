<div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="create-details">
    <div class="create-info" *ngIf="!onlyPlaced">
        <div class="create-background">
            <ng-container *ngFor="let info of roundsOptions; let i = index">
                <div class="info-list" [ngClass]="{'selectedInfo': info.isActive, 'spacer': info.spacer}"
                    (click)="changeInfo(info)">
                    <div class="img-label">
                        <img [src]="info.isActive ? info.icon1 : info.icon2" alt="">
                        <span>{{info.label}}</span>
                    </div>
                    <ng-container *ngIf="!loader; else loaderTemplate">
                        <span>{{info.count}}</span>
                    </ng-container>
                    <ng-template #loaderTemplate>
                        <div [ngClass]="{'active-loader': info.isActive}" class="loader"></div>
                    </ng-template>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="create-body1" [ngClass]="{ 'width100': onlyPlaced}">
        <div class="table-filters" [ngClass]="{'placed-filters': onlyPlaced}">
            <div class="filter-cont">
                <span class="middle-left card flex justify-content-center p-float-label"
                    *ngFor="let row of getFirstFiveFilters(); let i = index">
                    <p-multiSelect [options]="row.array" [(ngModel)]="selectedFilters[row.key]" optionLabel="label"
                        optionValue="value" [maxSelectedLabels]="1"
                        [selectedItemsLabel]="'{selectedFilters[row.filterName.toLowerCase()].length} ' + 'item selected'"
                        [placeholder]="row.filterName" (onChange)="handleFilterChange(row.key, $event)">
                        <ng-template let-option pTemplate="item">
                            <div [pTooltip]="option.label.length > 30 ? option.label : null" tooltipPosition="top">
                                {{ option.label.length > 40 ? option.label.substring(0, 40) + '...' : option.label }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                    <label style="font-size: var(--fs-medium);" for="campus">{{row.filterName}}</label>
                </span>

                <span class="flex gap-5" *ngIf="remainingFilters().length">  
                    <div class="more-filter"  (click)="moreFilter.toggle($event)">
                        <span class="more-filters-icon"><i class="pi pi-filter"></i></span>
                        <span class="more-filters">{{studentsEnum.MOREFILTERS}}
                            ({{this.filtersCount}})</span>
                    </div>
                </span>

                <div class="clear-container">
                    <span class="flex gap-5 align-center">
                        <div>
                            <span class="clear-all" (click)="clearAllFilters()">Clear All</span>
                        </div>
                    </span>
    
                    <span class="flex gap-5 align-center">
                        <div>
                            <span class="apply-filters" (click)="applyFilter()">Apply Filter</span>
                        </div>
                    </span>
                </div>
            </div>
            <!-- <div class="flex">
                <div class="left-icons" *ngIf="this.activeTab === 'eligible'">
                    <span class="flex align-center cursor">
                        <i class="pi pi-ellipsis-v" (click)="op.toggle($event); toggleOverlayPanel()" pTooltip="Action"
                            tooltipPosition="top" [ngClass]="{'active-icon': opVisible}"></i>
                    </span>
                    <div class="card flex justify-content-center student-action">
                        <p-overlayPanel #op [appendTo]="op" [style]="{ width: '180px' }" (onHide)="hideOverlay()">
                            <div *ngFor="let options of actionOptions">
                                <div class="action-option" (click)="onClickAction(options)">{{options.label}}</div>
                            </div>
                        </p-overlayPanel>
                    </div>
                </div>
            </div> -->
        </div>
        <div>
            <div class="table-cont table-relative" [ngClass]="{
                'rounds-table': !onlyPlaced,
                'placed-table': onlyPlaced
            }">
                <div #leftIcons class="student-action"></div>
                <app-table class="rounds-student-table" [ngClass]="{ 'placed-student-table': onlyPlaced }" [loadingSpinner]="tableLoader" [columns]="columns"
                    [dataList]="dataList" [dataCount]="dataCount" [pageLimits]="pageLimits" (tableOptionsEvent)="tableOptionsEvent($event)"
                    [captionsList]="captionsList" (tableActionClick)="tableAction($event)" [tableheight]="tableHeight"
                    [totalPage]="totalPage" [filterData]="filterData" [actionToSelect]="actionToSelect" (searchChange)="searchChange($event)"
                    [checkBoxSelectedCondition]="false" (search)="searchValueChange($event)" [actionTemplate]="actionTemplate"
                    (rowLength)="rowLengthChange($event)" [hideTemplate]="true" (pageNo)="pageChange($event)"
                    (checkboxStateChange)="handleCheckboxStateChange($event)" [isShowSelectedAll]="true"
                    [showDownload]="true" [searchBy]="search" (sortBy)="sortChange($event)"
                    (downloadEvent)="openDownloadDataSideBar()" [manually_actions]="{add: (this.activeTab === 'eligible' || this.activeTab === 'placed') && !onlyPlaced, remove: this.activeTab === 'eligible'}">
                </app-table>
            </div>
            <ng-template #actionTemplate let-rowData let-i="index">
                <div class="status_label" [ngClass]="getStatusClass(rowData.drive_status)">
                    {{rowData.drive_status | driveLabelPipe}}
                </div>
            </ng-template>
            <!-- <ng-template #linkTemplate let-rowData let-i="index">
                <div class="view-cont-resume">
                    <img *ngIf="rowData.resume_link" class="pdf-image" [src]="notificationPdf" alt="pdf-icon">
                    <span [ngClass]="{'hyper-link': rowData.resume_link}" (click)="redirectResume(rowData)">{{rowData.resume_link ?
                        'View' : '-'}}</span>
                </div>
            </ng-template> -->
            <!-- <ng-template #offerTemplate let-rowData let-i="index">
                <div class="view-cont">
                    <img *ngIf="rowData.offer_letter" class="pdf-image" [src]="notificationPdf"
                        alt="pdf-icon">
                    <span [ngClass]="{'hyper-link': rowData.offer_letter}"
                        (click)="rowData.offer_letter ? onClickFile(rowData.offer_letter) : null">{{rowData.offer_letter
                        ? 'View' : '-'}}</span>
                </div>
            </ng-template>
            <ng-template #confirmationTemplate let-rowData let-i="index">
                <div class="view-cont">
                    <img *ngIf="rowData.confirmation_letter" class="pdf-image"
                        [src]="notificationPdf" alt="pdf-icon">
                    <span class="hyper-link"
                        (click)="onClickFile(rowData.confirmation_letter)">{{rowData.confirmation_letter ? 'View' :
                        '-'}}</span>
                </div>
            </ng-template> -->

        </div>
    </div>
</div>

<p-overlayPanel #moreFilter (onHide)="onCloseMoreFilters()">
    <ng-template pTemplate="content">
        <div class="overlay-cnt">
            <span class="student-filter-student-page" *ngFor="let row of remainingFilters(); let i = index">
                <p-multiSelect [options]="row.array" [(ngModel)]="selectedFilters[row.key]" optionLabel="label"
                    optionValue="value" [maxSelectedLabels]="0"
                    [selectedItemsLabel]="'{selectedFilters[row.filterName.toLowerCase()].length} ' + 'item selected'"
                    [placeholder]="row.filterName">
                    <ng-template let-option pTemplate="item">
                        <div [pTooltip]="option.label.length > 30 ? option.label : null" tooltipPosition="top">
                            {{ option.label.length > 40 ? option.label.substring(0, 40) + '...' : option.label
                            }}
                        </div>
                    </ng-template>
                </p-multiSelect>
            </span>
        </div>
    </ng-template>
</p-overlayPanel>

<div *ngIf="visible && (sideBarPurpose === 'addStudents' || sideBarPurpose === 'removeStudents')">
    <p-sidebar class="add-students" [(visible)]="visible" position="right" (onHide)="clearInputs()"
        [dismissible]="false">
        <ng-template pTemplate="header">
            <div class="header-cont">
                <span>{{sideBarPurpose === 'addStudents' ? roundsEnum.ADDSTUDENTMANUALLY :
                    roundsEnum.REMOVESTUDENTMANUALLY}}
                </span>
                <button pbutton class="p-button-create blue-filled-btn" (click)="addStudentManually(sideBarPurpose)"
                    type="submit">{{sideBarPurpose === 'addStudents' ? roundsEnum.ADD : roundsEnum.REMOVE}}
                </button>
            </div>
            <p-divider class="width100" layout="horizontal"></p-divider>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="sidebar-body">
                <div class="title-box manually-added" *ngIf="sideBarPurpose === 'addStudents'">
                    <span>
                        Only students with an active drive status, who are interested in placement, whose program offer and pass-out year match
                        the eligibility criteria, and whose profile status is approved can be manually added.
                    </span>
                </div>
                <div class="move-students-input">
                    <p-checkbox [(ngModel)]="sendEmail" [binary]="true"></p-checkbox>
                    <span>Send Notifications</span>
                </div>
                <div class="add-using-students">
                    <span>{{roundsEnum.ADDUSING}}<span class="imp">*</span></span>
                    <div class="add-using">
                        <div *ngFor="let category of categories" class="field-checkbox">
                            <p-radioButton [value]="category" [(ngModel)]="selectedCategory"
                                (click)="categoryChange()"></p-radioButton>
                            <label [for]="category.key" class="ml-2">{{ category | fieldLabelPipe}}</label>
                        </div>
                    </div>
                    <div class="card flex justify-content-center p-float-label">
                        <p-chips [(ngModel)]="emailOrReg" separator="\n" (paste)="handlePaste($event)" (onRemove)="errorData = []; errorDataToDisplay = []" (keydown)="handleKeydown($event)"
                            placeholder="Type {{fieldMaps[selectedCategory]}} and press enter to add">
                        </p-chips>
                        <label for="{{selectedCategory}}">{{selectedCategory | fieldLabelPipe}}
                            <span class="imp">*</span>
                        </label>
                    </div>
                    <app-handle-error [clicked]="clicked" [ngModelValue]="emailOrReg.length"
                        name="{{selectedCategory}} is Required">
                    </app-handle-error>
                </div>

                <div class="error-message-preview" #tableContainer>
                    <div class="table-container" *ngIf="errorDataToDisplay.length">
                        <p-table styleClass="p-datatable-gridlines" [columns]="tableColumns" [value]="errorDataToDisplay">
                            <ng-template pTemplate="caption">
                                <div class="caption-header">
                                    <div>
                                        <span class="caption-header-text">Error Student Data</span>
                                    </div>
                                    <div class="pagination-container">
                                        <div class="totallist-text">Total List : {{errorData.length}}</div>
                                        <p-divider layout="vertical"></p-divider>
                                        <div><span class="pi pi-download arrow-button" (click)="downloadErrorData()"></span></div>
                                        <p-divider layout="vertical"></p-divider>
                                        <div class="pagination-items">
                                            <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                                                {{ currentPageErrorTable }} to {{ totalPageErrorTable }}
                                            </button>
                                            <div class="page-front-back">
                                                <span><i class="pi pi-angle-left arrow-button"
                                                        (click)="previousPagination()"></i></span>
                                                <span><i class="pi pi-angle-right arrow-button" (click)="nextPagination()"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" scope="col">
                                        {{ col.header }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <ng-container *ngFor="let col of columns">
                                        <td class="truncate-cell" [style.width]="col.width" pFrozenColumn>
                                            <span [pTooltip]="rowData[col.field]" [tooltipPosition]="'top'">
                                                {{ rowData[col.field] ? rowData[col.field] : "-" }}
                                            </span>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-sidebar>
</div>
<div *ngIf="visible && (sideBarPurpose === 'moreFilters')">
    <p-sidebar class="more-filt" [(visible)]="visible" position="right" [dismissible]="false"
        (onHide)="onCloseMoreFilters()">
        <ng-template pTemplate="header">
            <div class="header-cont">
                <span>{{studentsEnum.MOREFILTERS}}</span>
            </div>
            <p-divider layout="horizontal"></p-divider>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="bulkUpload-cnt">
                <span *ngFor="let row of remainingFilters(); let i = index">
                    <p-multiSelect [options]="row.array" [(ngModel)]="selectedFilters[row.key]" optionLabel="label"
                        optionValue="value" [maxSelectedLabels]="0"
                        [selectedItemsLabel]="'({selectedFilters[row.filterName.toLowerCase()].length}) ' + row.filterName"
                        [placeholder]="row.filterName">
                        <ng-template let-option pTemplate="item">
                            <div [pTooltip]="option.label.length > 30 ? option.label : null" tooltipPosition="top">
                                {{ option.label.length > 40 ? option.label.substring(0, 40) + '...' : option.label
                                }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </span>
            </div>
        </ng-template>
    </p-sidebar>
</div>
<div *ngIf="visible && (sideBarPurpose === 'setOfferType')">
    <p-sidebar class="more-filt" [(visible)]="visible" position="right" [dismissible]="false"
        (onHide)="onCloseSetOfferType()">
        <ng-template pTemplate="header">
            <div class="header-cont">
                <span>{{ activeTab!=='placed' ? studentsEnum.SETOFFER : studentsEnum.PLACEDSTUDENTS}}</span>
                <button pbutton class="p-button-create blue-filled-btn" (click)="setOfferType()" type="submit" [disabled]="activeTab==='placed' && invalidEmails?.length" [ngClass]="{'disabled': activeTab==='placed' && invalidEmails?.length}">Save
                </button>
            </div>
            <p-divider class="width100" layout="horizontal"></p-divider>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="offer-type-cont">
                <div class="gap-20 flex flex-column">
                    <ng-container *ngFor="let row of offerTypeProperty">
                        <div *ngIf="row.property === 'dropdown'" class="flex flex-column">
                            <div class="flex card justify-content-center p-float-label">
                                <p-dropdown *ngIf="row.property === 'dropdown' && visible" [options]="row.array"
                                    [(ngModel)]="row.values" [autoDisplayFirst]="false" optionLabel="{{row.label}}"
                                    optionValue="{{row.value}}" [placeholder]="row.filterName">
                                    <ng-template let-option pTemplate="row">
                                        <div [pTooltip]="option[row.label]?.length > 30 ? option[row.label] : null"
                                            tooltipPosition="top">
                                            {{ option[row.label]?.length > 40 ? option[row.label].substring(0, 40) +
                                            '...' :
                                            option[row.label]
                                            }}
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <label>{{row.property_name}}
                                    <span class="imp">*</span>
                                </label>
                            </div>
                            <app-handle-error [clicked]="clicked" [ngModelValue]="row.values"
                                name="{{row.error_key}} is Required">
                            </app-handle-error>
                        </div>
                    </ng-container>
                </div>
                <div class="offer-salary-cont">
                    <div class="profile-degree" *ngIf="true">
                        <p-checkbox [(ngModel)]="offerTypeFor.salary" (onChange)="salarySet()"
                            [binary]="true"></p-checkbox>
                        <span>Offered {{ salary_label }}</span>
                    </div>
                    <div class="offer-salary" *ngIf="true">
                        <p-checkbox [(ngModel)]="offerTypeFor.stipend" [binary]="true"
                            (onChange)="salarySet()"></p-checkbox>
                        <span>Offered Stipend</span>
                    </div>
                </div>
                <div class="flex flex-column">
                    <ng-container *ngFor="let row of offerTypeProperty">
                        <div *ngIf="row.property === 'input'" class="flex flex-column">
                            <div *ngIf="row.visible" class="card flex justify-content-center p-float-label">
                                <input type="number" inputmode="numeric" pInputText [(ngModel)]="row.values" (input)="validateInput($event, row)" (keydown)="preventInvalidCharacters($event)">
                                <label>{{row.property_name}}
                                    <span class="imp">*</span>
                                </label>
                            </div>
                            <div *ngIf="offerTypeFor[row.key]" class="salary-field">
                                <app-handle-error [clicked]="clicked" [ngModelValue]="row.values"
                                    name="{{row.error_key}} is Required">
                                </app-handle-error>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="sidebar-body" *ngIf="activeTab==='placed'">
                    <div class="move-students-input">
                        <p-checkbox [(ngModel)]="sendEmail" [binary]="true"></p-checkbox>
                        <span>Send Notifications</span>
                    </div>
                    <div class="add-using-students">
                        <span>{{roundsEnum.ADDVIA}}<span class="imp">*</span></span>
                        <div class="add-using">
                            <div *ngFor="let category of categories" class="field-checkbox">
                                <p-radioButton [value]="category" [(ngModel)]="selectedCategory"
                                    (click)="categoryChange()"></p-radioButton>
                                <label [for]="category.key" class="ml-2">{{ category | fieldLabelPipe}}</label>
                            </div>
                        </div>
                        <div class="card flex justify-content-center p-float-label">
                            <p-chips [(ngModel)]="emailOrReg" separator="\n" (paste)="handlePaste($event)"
                                (keydown)="handleKeydown($event)" placeholder="Type email and press enter to add"
                                (onRemove)="handleRemove($event)">
                                <ng-template let-email let-index pTemplate="item">
                                    <div [ngClass]="{'invalid-email': !isLoading && (invalidEmails?.includes(email.toLowerCase()) || !validEmails?.includes(email.toLowerCase()))}">
                                        {{ email }}
                                    </div>
                                </ng-template>
                            </p-chips>
                            <label for="{{selectedCategory}}">{{selectedCategory | fieldLabelPipe}}
                                <span class="imp">*</span>
                            </label>
                        </div>
                        <app-handle-error [clicked]="clicked" [ngModelValue]="emailOrReg.length"
                            name="{{selectedCategory}} is Required">
                        </app-handle-error>
                    </div>
                </div>
                <div class="error-message-preview" *ngIf="activeTab==='placed'" #tableContainer>
                    <div class="table-container" *ngIf="errorDataToDisplay.length">
                            <p-table styleClass="p-datatable-gridlines" [columns]="tableColumns" [value]="errorDataToDisplay">
                                <ng-template pTemplate="caption">
                                    <div class="caption-header">
                                        <div>
                                            <span class="caption-header-text">Error Student Data</span>
                                        </div>
                                        <div class="pagination-container">
                                            <div class="totallist-text">Total List : {{errorData.length}}</div>
                                            <p-divider layout="vertical"></p-divider>
                                            <div><span class="pi pi-download arrow-button" (click)="downloadErrorData()"></span></div>
                                            <p-divider layout="vertical"></p-divider>
                                            <div class="pagination-items">
                                                <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                                                    {{ currentPageErrorTable }} to {{ totalPageErrorTable }}
                                                </button>
                                                <div class="page-front-back">
                                                    <span><i class="pi pi-angle-left arrow-button" (click)="previousPagination()"></i></span>
                                                    <span><i class="pi pi-angle-right arrow-button" (click)="nextPagination()"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th *ngFor="let col of columns" scope="col">
                                            {{ col.header }}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <ng-container *ngFor="let col of columns">
                                            <td class="truncate-cell" [style.width]="col.width" pFrozenColumn>
                                                <span [pTooltip]="rowData[col.field]" [tooltipPosition]="'top'">
                                                    {{ rowData[col.field] ? rowData[col.field] : "-" }}
                                                </span>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-template>
                            </p-table>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-sidebar>
</div>

<input type="file" #fileInput style="display: none" accept=".pdf" (change)="onFileSelected($event)">

<app-send-email [sendemailslider]="sendemailslider" [studentDataList]="studentDataList"
    [selectAllStudentCount]="selectAllStudentCount" [selectAllStudents]="selectAllStudents"
    (closesideBar)="closesideBar($event)" (loading)="loadingInitiate($event)" [filterData]="filterData"
    [selectedFilters]="selectedFilters" [roundBasedEmail]="true" [driveMailInfo]="driveMailInfo" [search]="searchValue">
</app-send-email>

<div class="preview-offer-confirmation" *ngIf="sideBarPurpose === 'previewOfferOrConfirmation'">
    <p-dialog header="Header" [resizable]="false" [draggable]="false" [modal]="true" [(visible)]="visible"
        [style]="{ width: '60vw', height: '80vw' }" (onHide)="cancelUpload()">
        <ng-template pTemplate="header">
            <span class="text-xl font-bolder header">Offer Letter preview</span>
        </ng-template>
        <div class="flex flex-column">
            <div class="std-data">

                <div class="flex gap-10">
                    <div class="flex-1">
                        <span class="label">Student Name</span>
                        <span class="value value-divider">{{selectedOptions[0].first_name}}
                            {{selectedOptions[0].last_name}}</span>
                    </div>
                    <div class="flex-1">
                        <span class="label">Registration Number</span>
                        <span class="value value-divider">{{selectedOptions[0].registration_number}}</span>
                    </div>
                    <div class="flex-1">
                        <span class="label">Email ID</span>
                        <span class="value value-divider">{{selectedOptions[0].primary_email}}</span>
                    </div>
                </div>
            </div>
            <pdf-viewer *ngIf="pdfSrc" class="pdf-viewer" [src]="pdfSrc" [render-text]="true" [fit-to-page]="true"
                [autoresize]="true" style="width: 58vw; height: 68vh"></pdf-viewer>
        </div>
        <ng-template pTemplate="footer">
            <div class="footer-upload">
                <span class="flex gap-5 align-center">
                    <span style="width: 110px;height: 38px;" class="custom-button">
                        <app-button [btnProperties]="rbtnProperties['cancel']"
                            (buttonClick)="cancelUpload()"></app-button>
                    </span>
                </span>
                <span class="flex gap-5 align-center">
                    <span style="width: 110px;height: 38px;" class="custom-button">
                        <app-button [btnProperties]="rbtnProperties['upload']"
                            (buttonClick)="confirmUpload()"></app-button>
                    </span>
                </span>
            </div>
        </ng-template>
    </p-dialog>
</div>

<div class="move-students">
    <p-sidebar class="move-students-sidebar" [(visible)]="showMoveStudents" position="right" [dismissible]="false"
        (onHide)="clearValues()" width="500px">

        <ng-template pTemplate="header">
            <div class="move-students-header">
                <span class="move-students-text">Move To</span>
                <div class="header-btn">
                    <app-button [btnProperties]="btnProperties['saveStudent']"
                        (buttonClick)="driveMoveToRounds()"></app-button>
                </div>
            </div>

            <p-divider layout="horizontal" class="p-divider-width"></p-divider>
        </ng-template>

        <ng-template pTemplate="body">

            <div class="move-students-body">
                <div class="move-students-container">
                    <span class="text-header"> Selected </span>
                    <span class="text-body"> {{ moveStudentsActions.selectAll ?  dataCount : moveStudentsActions.student_data.length }} {{
                        moveStudentsActions.student_data.length == 1 ? 'student' : 'students' }} </span>
                </div>

                <div class="move-students-container">
                    <span class="text-header">Move</span>

                    <div class="round-movement">
                        <span class="from-stage">{{ moveStudentsActions.active_tab }}</span>
                        <img class="round-movement-img" [src]="rightArrow"
                            alt="Arrow-right">
                        <span class="to-stage">{{ moveStudentsActions.stage }}</span>
                    </div>
                </div>

                <div class="move-students-input">
                    <p-checkbox [(ngModel)]="sendEmail" [binary]="true"></p-checkbox>
                    <span>Send Notifications</span>
                </div>
                
                <form *ngIf="needToShowMultipleProfileSelection()" class="selected-profile">
                    <span class="text-header">Select Interested Profile</span>
                    <ng-container *ngIf="driveData.preference_count; else dropDown">
                        <span class="p-float-label">
                            <p-multiSelect class="dropdown" inputId="dropdown" 
                                [options]="driveData.profile_designation" [formControl]="selectedProfileForm"
                                [required]="true" placeholder="Select Profile"
                                (onChange)="onProfileChange($event)"
                                [styleClass]="selectedProfileForm.touched && selectedProfileForm.errors ? 'invalid' : ''">
                                <ng-template pTemplate="selectedItems">
                                    <span>{{selectedProfileForm.value.length}} items Selected</span>                               
                                </ng-template>
                            </p-multiSelect>
                            <label for="dropdown">Select any {{driveData.preference_count}} preferred profile<span class="imp">*</span></label>
                        </span>

                        <ng-container *ngIf="driveData.allow_priority && selectedProfileForm.valid && selectedProfileForm?.value.length> 1">
                            <div class="profile-reorder" #reorder>
                                <div cdkDropList class="profile-list" (cdkDropListDropped)="drop($event)">
                                    <ng-container *ngFor="let profile of priorityArray; let i=index">
                                        <div class="profile-container" cdkDrag>
                                            <div class="profile-drag" *cdkDragPlaceholder>
                                                <div class="profile-label">
                                                    <div class="num-circle" [ngClass]="{'circle-1': i % 3 === 0, 'circle-2': i % 3 === 1, 'circle-3': i % 3 === 2}">
                                                        <span class="num">{{i + 1}}</span>
                                                    </div>
                                                    <span class="profile">{{profile}}</span>
                                                </div>
                                                <div class="icons">
                                                    <i class="pi pi-ellipsis-v"></i>
                                                    <i class="pi pi-ellipsis-v"></i>
                                                </div>
                                            </div>
                                            <div class="profile-label">
                                                <div class="num-circle" [ngClass]="{'circle-1': i % 3 === 0, 'circle-2': i % 3 === 1, 'circle-3': i % 3 === 2}">
                                                    <span class="num">{{i + 1}}</span>
                                                </div>
                                                <span class="profile">{{profile}}</span>
                                            </div>
                                            <div class="icons">
                                                <i class="pi pi-ellipsis-v"></i>
                                                <i class="pi pi-ellipsis-v"></i>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>                              
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-template #dropDown>
                        <span class="p-float-label">
                            <p-dropdown class="dropdown" inputId="dropdown" optionLabel="label"
                                optionValue="value" [options]="driveData.profile_designation" [formControl]="selectedProfileForm"
                                [required]="true" placeholder="Select Profile"
                                [styleClass]="selectedProfileForm.touched && selectedProfileForm.errors ? 'invalid' : ''">
                            </p-dropdown>
                            <label for="dropdown">Select preferred profile<span class="imp">*</span></label>
                        </span>
                    </ng-template>
                </form>

                <div class="offer-type-container" *ngIf="moveStudentsActions.to_stage === 'placed'">
                    <span class="text-header">Set Offer Type</span>
                    <div class="offer-type-cont">
                        <div class="gap-20 flex flex-column">
                            <ng-container *ngFor="let row of offerTypeProperty">
                                <div *ngIf="row.property === 'dropdown'" class="flex flex-column">
                                    <div class="flex card justify-content-center p-float-label">
                                        <p-dropdown *ngIf="row.property === 'dropdown'" [options]="row.array"
                                            [(ngModel)]="row.values" [autoDisplayFirst]="false" optionLabel="{{row.label}}"
                                            optionValue="{{row.value}}" [placeholder]="row.filterName">
                                            <ng-template let-option pTemplate="row">
                                                <div [pTooltip]="option[row.label]?.length > 30 ? option[row.label] : null"
                                                    tooltipPosition="top">
                                                    {{ option[row.label]?.length > 40 ? option[row.label].substring(0, 40) +
                                                    '...' :
                                                    option[row.label]
                                                    }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <label>{{row.property_name}}
                                            <span class="imp">*</span>
                                        </label>
                                    </div>
                                    <app-handle-error [clicked]="clicked" [ngModelValue]="row.values" name="{{row.error_key}} is Required">
                                    </app-handle-error>
                                </div>
                            </ng-container>
                        </div>
                        <div class="offer-salary-cont">
                            <div class="offer-salary">
                                <p-checkbox [(ngModel)]="offerTypeFor.stipend" [binary]="true" (onChange)="salarySet()"></p-checkbox>
                                <span>Offered Stipend</span>
                            </div>
                            <div class="profile-degree">
                                <p-checkbox [(ngModel)]="offerTypeFor.salary" (onChange)="salarySet()" [binary]="true"></p-checkbox>
                                <span>Offered {{ salary_label }}</span>
                            </div>
                        </div>
                        <div class="flex flex-column">
                            <ng-container *ngFor="let row of offerTypeProperty">
                                <div *ngIf="row.property === 'input'" class="flex flex-column">
                                    <div *ngIf="row.visible" class="card flex justify-content-center p-float-label">
                                        <input type="number" inputmode="numeric" pInputText [(ngModel)]="row.values"
                                            (input)="validateInput($event, row)" (keydown)="preventInvalidCharacters($event)">
                                        <label>{{row.property_name}}
                                            <span class="imp">*</span>
                                        </label>
                                    </div>
                                    <div *ngIf="offerTypeFor[row.key]" class="salary-field">
                                        <app-handle-error [clicked]="clicked" [ngModelValue]="row.values"
                                            name="{{row.error_key}} is Required">
                                        </app-handle-error>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <span class="move-students-container">
                    <span class="text-header">Reason</span>
                    <textarea placeholder="Type Reason" class="remarks" rows="8" cols="100"
                        style="width: 500px; resize: none;" pInputTextarea
                        [(ngModel)]="reasonForMovingRound"></textarea>
                </span>

            </div>

        </ng-template>

    </p-sidebar>

    <p-sidebar class="download-students" [(visible)]="showDownData" position="right" [dismissible]="false"
        (onHide)="hideDownloadBar()" width="650px">

        <ng-template pTemplate="header">
            <div class="move-students-header">
                <span class="move-students-text">Download</span>
                <div class="header-btn">
                    <app-button [btnProperties]="btnProperties['downloadStudent']"
                        (buttonClick)="downloadData()"></app-button>
                </div>
            </div>

            <p-divider layout="horizontal" class="p-divider-width"></p-divider>
        </ng-template>

       
        <ng-template pTemplate="body">
            <div class="sidebar-body">
                <div class="radioButton-group">
                    <span>Download as</span>
                    <div class="radioButton">
                        <p-radioButton value="excel" inputId="excel" [(ngModel)]="downloadColumnAs">
                        </p-radioButton>
                        <label for="excel"
                            [style]="{color: downloadColumnAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                                class="pi pi-file"></em>Excel</label>
                    </div>
                    <div class="radioButton">
                        <p-radioButton value="csv" inputId="csv" [(ngModel)]="downloadColumnAs">
                        </p-radioButton>
                        <label for="csv"
                            [style]="{color: downloadColumnAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                                class="pi pi-file-excel"></em>CSV</label>
                    </div>
                </div>
            </div>

            <div *ngFor="let categories of downloadCategories; let i = index" class="fields-container">
                <div class="title-box">
                    <span>{{categories.header}}</span>
                </div>

                <div *ngIf="categories.data.length && !isLoading">
                    <div class="selectAll-checkbox field-checkbox" *ngIf="categories.type !== 'resume'">
                        <p-checkbox [binary]="true" (onChange)="selectAllDownloadData(i)" [(ngModel)]="categories.isSelectedAll"></p-checkbox>
                        <span>Select All</span>
                    </div>

                    <div class="fields">
                        <div *ngFor="let field of categories.data" class="field-checkbox">
                            <p-checkbox [binary]="true" [(ngModel)]="field.active" (onChange)="onDropDownChange(i)"></p-checkbox>
                            <label [for]="field.label">{{field.label}}</label>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLoading; else noDataTemplate">
                    <div class="no-data">
                        <span>Loading...</span>
                    </div>
                </div>
                  
                <ng-template #noDataTemplate>
                    <div *ngIf="!categories || !categories.data || !categories.data.length" class="no-data">
                      <span>No Fields available</span>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </p-sidebar>
</div>