import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpService: HttpService) { }

    async accountsLookup(payload: any) {
        return await this.httpService.post('/auth/admin/lookup', payload);
    };

    async requestVerify(payload: any) {
        return await this.httpService.post('/auth/password/forgot/validate', payload);
    };

    async login(payload: any) {
        return await this.httpService.post('/auth/admin/token', payload);
    }

    async changePassword(payload: any) {
        return await this.httpService.post('/auth/password/forgot/change', payload);
    };

    async forgotPassword(payload: any) {
        return await this.httpService.post('/auth/password/forgot', payload);
    }

    async domainValidation() {
        return await this.httpService.get('/auth/domains/validate');
    }
}
