import { environment } from '../environments/environment';

const assets_bucket = environment.NODE_ENV === "production" ? "pat-prod" : "pat-dev";

const prefix = `https://storage.cloud.google.com`;

//bucket
const assetsBucket = `/${prefix}/${assets_bucket}`;
const localAssets = `/assets`;

// path
const sampleData = `${assetsBucket}/sample_data/`


// assets files
const xlsxIcon = `${localAssets}/icons/xlsx.svg`;
const mailIcon = `${localAssets}/icons/mail.svg`;
const pushIcon = `${localAssets}/icons/push.svg`;
const totalIcon = `${localAssets}/icons/total.svg`;
const whatsappIcon = `${localAssets}/icons/whatsapp.svg`;
const editIcon = `${localAssets}/icons/edit.svg`;
const setTableDefaultIcon = `${localAssets}/icons/set-table-default.svg`;
const manuallyAdded = `${localAssets}/icons/manually-added.svg`;
const defaultLogo = `${localAssets}/logos/iamneo.jpg`;
const defaultLoginBackground = `${localAssets}/background/login.jpg`;
const chatBackground = `${localAssets}/background/chat-bg.png`;
const pattern = `${localAssets}/background/pattern.png`;
const defaultProfile = `${localAssets}/header/defaultImage.png`;
const notificationXlsx = `${localAssets}/notification/xlsx.svg`;
const notificationCsv = `${localAssets}/notification/csv.svg`;
const notificationPdf = `${localAssets}/notification/pdf.svg`;
const notificationDoc = `${localAssets}/notification/docx.svg`;
const notificationZip = `${localAssets}/notification/zip.svg`;
const emailBoxIcon = `${localAssets}/notification/email.svg`;
const pushMessageIcon = `${localAssets}/notification/pushMessage.svg`;
const eligibilityAddIcon = `${localAssets}/eligibility/eligibility-add.svg`;
const trashIcon = `${localAssets}/icons/trash-icon.svg`;
const plusIcon = `${localAssets}/icons/plus-icon.svg`;
const chatProfileIcon = `${localAssets}/icons/chat_profile.svg`;
const broadcastProfileIcon = `${localAssets}/icons/broadcast.svg`;
const profilePicBackground = `${localAssets}/background/profile-pic-bck.png`;
const rightArrow = `${localAssets}/icons/right-arrow.svg`;
const filterIcon = `${localAssets}/icons/filter-icon.svg`;
const errorIcon = `${localAssets}/icons/error-icon.svg`;
const eligiblieWhite = `${localAssets}/eligibility/eligibile-white.svg`;
const eligiblieBlack = `${localAssets}/eligibility/eligibile-black.svg`;
const optoutActive = `${localAssets}/eligibility/optout-active.svg`;
const optoutInactive = `${localAssets}/eligibility/optout-inactive.svg`;
const optinActive = `${localAssets}/eligibility/optin-active.svg`;
const optinInactive = `${localAssets}/eligibility/optin-inactive.svg`;
const placedActive = `${localAssets}/eligibility/placed-active.svg`;
const placedInactive = `${localAssets}/eligibility/placed-inactive.svg`;
const rejectedActive = `${localAssets}/eligibility/rejected-active.svg`;
const rejectedInactive = `${localAssets}/eligibility/rejected-inactive.svg`;
const removedActive = `${localAssets}/eligibility/removed-active.svg`;
const removedInactive = `${localAssets}/eligibility/removed-inactive.svg`;
const waitingInactive = `${localAssets}/eligibility/waiting-inactive.svg`;
const waitingActive = `${localAssets}/eligibility/waiting-active.svg`;
const notEligibleInactive = `${localAssets}/eligibility/not-eligible-inactive.svg`;
const notEligibleActive = `${localAssets}/eligibility/not-eligible-active.svg`;
const drivePattern = `${localAssets}/eligibility/pattern.svg`;
const neoPatIcon = `${localAssets}/sidebar/neopat-icon.jpg`;
const sideBarStudentIcon = `${localAssets}/sidebar/students.svg`;
const sideBarDriveIcon = `${localAssets}/sidebar/drives.svg`;
const sideBarEligibilityIcon = `${localAssets}/sidebar/eligibility.svg`;
const sideBarEmailTemplateIcon = `${localAssets}/sidebar/email-template.svg`;
const sideBarProgrammeMappingIcon = `${localAssets}/sidebar/programme-mapping.svg`;
const sideBarSettingsIcon = `${localAssets}/sidebar/settings.svg`;
const sideBarPattern = `${localAssets}/sidebar/Navigation-pattern.svg`;
const question = `${localAssets}/settings/question.svg`;
const addStudentsIcon = `${localAssets}/eligibility/add_students_drives.svg`;
const removeStudentsIcon = `${localAssets}/eligibility/remove_students_drives.svg`;
const republishQueueIcon = `${localAssets}/sidebar/republish-queue.svg`;
const customMessageIcon = `${localAssets}/sidebar/custom-message.svg`;
const drivePlannerIcon = `${localAssets}/sidebar/drive-planner.svg`;
const chatIcon = `${localAssets}/sidebar/chat.svg`;
const appStoreIcon = `${localAssets}/login/app-store.png`;
const playStoreIcon = `${localAssets}/login/play-store.png`;
const reminderIcon = `${localAssets}/sidebar/reminders.svg`;
const metricsIcon = `${localAssets}/sidebar/metrics.svg`;
const resetPasswordTemplate = `${sampleData}/set-custom-password-template.xlsx`

export {
    resetPasswordTemplate,
    xlsxIcon,
    editIcon,
    setTableDefaultIcon,
    manuallyAdded,
    defaultLogo,
    defaultLoginBackground,
    pattern,
    defaultProfile,
    notificationXlsx,
    notificationCsv,
    notificationPdf,
    notificationDoc,
    notificationZip,
    emailBoxIcon,
    pushMessageIcon,
    eligibilityAddIcon,
    trashIcon,
    plusIcon,
    profilePicBackground,
    rightArrow,
    filterIcon,
    errorIcon,
    eligiblieWhite,
    eligiblieBlack,
    optoutActive,
    optoutInactive,
    optinActive,
    optinInactive,
    placedActive,
    placedInactive,
    rejectedActive,
    rejectedInactive,
    removedActive,
    removedInactive,
    neoPatIcon,
    sideBarStudentIcon,
    sideBarDriveIcon,
    sideBarEligibilityIcon,
    sideBarEmailTemplateIcon,
    sideBarProgrammeMappingIcon,
    sideBarSettingsIcon,
    sideBarPattern,
    drivePattern,
    question,
    addStudentsIcon,
    removeStudentsIcon,
    republishQueueIcon,
    customMessageIcon,
    waitingInactive,
    notEligibleInactive,
    waitingActive,
    notEligibleActive,
    appStoreIcon,
    playStoreIcon,
    drivePlannerIcon,
    reminderIcon,
    mailIcon,
    totalIcon,
    whatsappIcon,
    pushIcon,
    metricsIcon,
    chatIcon,
    chatBackground,
    chatProfileIcon,
    broadcastProfileIcon
};