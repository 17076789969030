import { TableModule } from 'primeng/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from '../button/button.module';
import { PipeModule } from '@pipes';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
@NgModule({
  declarations: [
    TableComponent,
  ],
  imports: [
    CommonModule,
    PipeModule,
    TableModule,
    CheckboxModule,
    DividerModule,
    OverlayPanelModule,
    FormsModule,
    DropdownModule,
    TooltipModule,
    SidebarModule,
    AccordionModule,
    RadioButtonModule,
    ButtonModule,
    DialogModule,
    ToastModule
  ],
  exports:[TableComponent]
})
export class TableComponentModule { }
