import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RoundsService {

  constructor(private http: HttpService) { }

  async fetchForInterviewProcess(payload: any) {
    const fetchForInterviewProcess =
      await this.http.post(
        '/drives/fetchForInterviewProcess',
        payload
      );
    return fetchForInterviewProcess;
  }

  async offerLetterOperation(payload: any, drive_id: string, type: string) {
    const offerLetterOperation =
      await this.http.post(
        `/drives/${drive_id}/drive-student/offer-letter/${type}`,
        payload
      );
    return offerLetterOperation;
  }

  async getCountOfEveryStages(drive_id: string, type: string) {
    const getCountOfEveryStages =
      await this.http.get(
        `/drives/${drive_id}/drive-student/count/${type}`
      );
    return getCountOfEveryStages;
  }

  async removeOfferOrConfirmation(payload: any) {
    const removeOfferOrConfirmation =
      await this.http.post(
        '/drives/removeOfferOrConfirmation',
        payload
      );
    return removeOfferOrConfirmation;
  }

  async drivesStudentDataDownload(payload: any, drive_id: string) {
    const drivesStudentDataDownload =
      await this.http.post(
        `/drives/${drive_id}/drive-student/files/download`,
        payload
      );
    return drivesStudentDataDownload;
  };

  async driveMoveToRounds(payload: any, drive_id: string) {
    const driveMoveToRounds =
      await this.http.post(
        `/drives/${drive_id}/drive-student/move-round`,
        payload
      );
    return driveMoveToRounds;
  };

  async validatePlacedEmails(payload: any) {
    try {
      const response = await this.http.post(`/drives/${payload?.drive_id}/student/validate`, payload);
      return response;
    } catch (error) {
      console.error('Error validating placed emails:', error);
      throw new Error('Failed to validate placed emails. Please try again later.');
    }
  }  
}
