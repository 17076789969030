import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DriveService {
  navigationState: number = 0;
  campusDetails: any = null;
  campus: any = null;
  driveTypes: any = null;
  companyCategories: any = null;
  companies: any = null;
  placementCategories: any = null;
  salaryLimit = 1000000000000;

  constructor(private globalService: GlobalService, private http: HttpService) { }

  async filterDriveData(payload: any, type: string) {
    return await this.http.post(`/drives/filter/${type}`, payload);
  }
  
  async getDrivesForReminders(payload: any) {
    const result = await this.http.post('/drives/getDrivesForReminders', payload);
    return result;
  }
  
  async getSpecificDrive(drive_id: string) {
    const result = await this.http.get(`/drives/${drive_id}`);
    return result.data;
  }

  async fetchAccounts(group_account_id: string) {
    if(!this.campus) {
      const response = await this.globalService.fetchAccounts(group_account_id);
      this.campus = response.data;
    }
    return this.campus;
  }

  async fetchCampusDetails(group_account_id: string) {
    if(!this.campusDetails) {
      const response = await this.globalService.fetchCampusDetails(group_account_id);
      this.campusDetails = response.data;
    }

    return this.campusDetails;
  }

  async getDriveTypes() {
    if(!this.driveTypes) {
      this.driveTypes = await this.globalService.getDriveTypes();
    }

    return this.driveTypes;
  }

  async getCompanyCategories() {
    if(!this.companyCategories) {
      this.companyCategories = await this.globalService.getCompanyCategories();
    }

    return this.companyCategories;
  }

  async getPlacementCategories() {
    if(!this.placementCategories) {
      this.placementCategories = await this.globalService.getPlacementCategories();
    }

    return this.placementCategories;
  }
  
  async getCompanies() {
    if(!this.companies) {
      this.companies = await this.globalService.getCompanies();
    }

    return this.companies;
  }

  async sendDriveReminder(payload: any, drive_id: string) {
    return await this.http.post(`/republish/${drive_id}/reminder`, payload);
  }

  clearCache() {
    this.campus = null;
    this.campusDetails = null;
    this.driveTypes = null;
    this.companyCategories = null;
    this.companies = null;
  }

  async driveStatusChange(drive_id: string, status: string, payload = {}) {
    return await this.http.patch(`/drives/${drive_id}/status/${status}`, payload);
  }

  async drivePublish(payload: any, drive_id: string) {
    return await this.http.post(`/republish/${drive_id}/publish`, payload);
  }

  async createDrive(payload: any) {
    return await this.http.post('/drives', payload);
  }

  async updateDrive(drive_id: string, payload: any) {
    return await this.http.put(`/drives/${drive_id}`, payload);
  }

  async cloneDrive(drive_id: string) {
    return await this.http.post(`/drives/${drive_id}/clone`, null);
  }

  async deleteDrive(drive_id: string) {
    return await this.http.delete(`/drives/${drive_id}`);
  }

  async getCountForDriveListing(payload: any) {
    return await this.http.post('/drives/student-count', payload);
  }

  async driveDataDownload(drive_id: string, payload: any) {
    return await this.http.post(`/drives/${drive_id}/drive-student/download`, payload);
  }

  async saveDriveCriteria(drive_id: string, payload: any) {
    return await this.http.patch(`/drives/${drive_id}/criteria`, payload);
  }

  async getCompanyLogo(search: string) {
    const data = await fetch('https://autocomplete.clearbit.com/v1/companies/suggest?query=' + search);
    return await data.json();
  }

  async driveSpecificNotification(payload: { drive_id: string , sendNotification: boolean }) {
    return await this.http.post(`/drives/driveSpecificNotification`, payload);
  }

  async checkDriveInRepublishQueue(drive_id: string) {
    const response = await this.http.get(`/republish/${drive_id}/check`);
    return response.data;
  }

  async manuallyAddRemoveStudents(payload: any, drive_id: string, type: string) {
    const manuallyAddRemoveStudents =
      await this.http.post(
        `/drives/${drive_id}/drive-student/add-remove/${type}`,
        payload
      );
    return manuallyAddRemoveStudents;
  }
} 
