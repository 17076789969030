import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FileUploadService, GlobalService } from '@services';
import { btnProperties } from '@models';
import { Message, MessageCategoryOptions, MessageTypeOptions, SBtnProperties, Severity, Students, Summary } from '@enum';
import * as XLSX from 'xlsx';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';


declare let tinymce: any;
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})

export class SendEmailComponent implements OnInit {

  @Input() sendemailslider: boolean = false;
  @Input() filterData: any = [];
  @Input() selectedFilters: any = {};
  @Output() closesideBar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() studentDataList: any = [];
  @Input() selectAllStudentCount: number = 0;
  @Input() selectAllStudents: boolean = false;
  @Input() roundBasedEmail: boolean = false;
  @Input() driveMailInfo: any = {};
  @Input() search: string = '';

  templatesList: any = [];
  userData: any = localStorage.getItem('user_details')
  accountData: any = localStorage.getItem('account_details')
  group_account_id: string = '';
  clicked: boolean = false;
  selectedStudentsCount: number = 2;
  studentData: any = [];
  btnProperties: { [key: string]: btnProperties } = SBtnProperties
  filePath: any;
  bucketData: any = {};
  excel: any;
  preview: boolean = false;
  mediaFile: File[] = [];
  isFileRemoved: boolean = true;
  previewColumn: any[] = [];
  previewDataList: any[] = [];
  pageSize: number = 50;
  currentPreviewPage: number = 1;
  totalPreviewPage: number = 0;
  displayDataList: any[] = [];
  defaultEditorPlaceholder: string = 'Email Description';
  messageTypeOptions = MessageTypeOptions;
  messageCategoryOptions = MessageCategoryOptions;
  selectedMessageType: string | null = null;
  uploadingFile: boolean = false;
  sendEmailForm: FormGroup;
  toAddress: string[] = [];
  subjectTitle: string = '';
  isFileUploading: boolean = false;
  emails: any[] = [];
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  invalidEmails: any[] = [];
  invalidEmailsMap: Map<string, boolean> = new Map();
  duplicates: string[] = [];
  isInvalidEmails: boolean = false;
  studentsEnum: any = Students;
  campaignType: any = '';
  invalidEmailsList: any[] = [];
  validUserIds: any[] = [];
  showSendEmail: boolean = false;

  constructor(private fb: FormBuilder, public globalService: GlobalService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService
  ) {
    this.userData = JSON.parse(this.userData)
    this.accountData = JSON.parse(this.accountData)
    this.sendEmailForm = this.fb.group({
      template: [''],
      content: [''],
      category: [''],
      messageType: [''],
      subjectTitle: [this.subjectTitle],
      attachment: [[]],
      emails: [[]],
      from: this.accountData.from_address
    });
  }

  async ngOnInit() {
    // this.getAllTemplates();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['sendemailslider'] && changes['sendemailslider'].currentValue === true) {
      this.loading.emit(true);
      this.getAllTemplates();
      this.loading.emit(false);
    }
  }

  async fileUpload(files: any) {
    try {
      const length = (this.sendEmailForm.get('attachment')?.value || []).length;
      const maxFilesCount = 10;

      if (length >= maxFilesCount) {
        this.messageService.add({
          severity: Severity.ERROR,
          summary: Message.MAXFILES,
          detail: `You can only upload maximum ${maxFilesCount} files.`
        });
        return;
      };
      const maxFileSize = 10 * 1024 * 1024;  // 10 MB max file size
      this.isFileUploading = true;

      const uploadPromises = files.addedFiles.map(async (file: any) => {
        if (file.size > maxFileSize) {
          this.messageService.add({
            severity: Severity.ERROR,
            summary: Message.FILELARGE,
            detail: `The file size of ${file.name} should not exceed 10MB.`
          });
          return;
        }

        let fileName = file.name.split('.');
        let fileExtension = fileName.pop();
        fileName = `${fileName.join('').replace(/\s/g, '').replace(/[^\w\s]/gi, '')}.${fileExtension}`;

        this.group_account_id = this.accountData.group_account_id;
        const filePath = `${this.group_account_id}/send-email/${uuidv4()}/${fileName}`;
        this.bucketData = {
          bucketName: this.globalService.bucketName,
          fileName: filePath,
          type: file.type,
        };
        const url = await this.globalService.getSignedUploadUrl(this.bucketData);
        if (url?.data?.response) {
          await this.fileUploadService.uploadUsingSignedUrl(url.data.response, file);
          const attachments = this.sendEmailForm.get('attachment')?.value || [];
          attachments.push(filePath);
          this.sendEmailForm.get('attachment')?.setValue(attachments);
          this.messageService.add({
            severity: Severity.SUCCESS,
            summary: Summary.UPLOADSUCCESS,
            detail: `File ${file.name} uploaded successfully.`
          });
        } else {
          throw new Error("Failed to obtain signed URL for " + file.name);
        }
      });
      await Promise.all(uploadPromises);
      this.isFileUploading = false;
    } catch (error) {
      console.error('Error uploading files', error);
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.UPLOADERR,
        detail: 'There was an error uploading files.'
      });
    } finally {
      this.uploadingFile = false;
    }
  }

  onMessageTypeChange(event: any): void {
    this.sendEmailForm.reset();
    this.selectedMessageType = event.value;
    this.sendEmailForm.get('messageType')?.setValue(this.selectedMessageType);
    const subjectTitleControl = this.sendEmailForm.get('subjectTitle');
    if (this.selectedMessageType !== 'whatsapp') {
      subjectTitleControl?.setValidators([Validators.required]);
    } else {
      subjectTitleControl?.clearValidators();
    }
    subjectTitleControl?.updateValueAndValidity();
    this.sendEmailForm.get('content')?.setValue('');
    this.sendEmailForm.get('from')?.setValue(this.accountData.from_address);
    setTimeout(() => {
      this.loadTinyMce();
    }, 500);
    this.showSendEmail = this.studentDataList.length || this.selectAllStudentCount;
  }

  async validateEmail() {
    let email_payload = {
      emails: this.sendEmailForm.get('emails')?.value,
      group_account_id: this.group_account_id,
      type: this.selectedMessageType
    };
    if (email_payload.emails.length === 0 || this.invalidEmailsMap.size > 0) {
      this.showSendEmail = false;
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.VALIDEMAIL
      });
      return;
    }

    this.loading.emit(true);
    let response = await this.globalService.validateEmail(email_payload);
    this.invalidEmailsList = response.data.notValidateEmail;
    this.validUserIds = response.data.validUserIds;
    if (this.invalidEmailsList.length === 0) {
      this.showSendEmail = true;
      this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.EMAILSVALID
      });
    }
    else {
      this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.INVALID,
        detail: this.selectedMessageType === 'whatsapp' ? Message.INVALIDWPEMAIL : Message.EXISTINGEMAIL
      });
    }
    this.loading.emit(false);
  }

  async handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData: any = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    // Split the pasted text into individual emails using newlines and spaces as separators
    let pastedEmails = pastedText.split(/[\n\s]+/).map((email: any) => email.trim().toLowerCase());
    if (pastedEmails.length > 0) {
      let currentEmails = this.sendEmailForm.get('emails')?.value || [];
      let validEmails = currentEmails.filter((email: any) => this.emailPattern.test(email) && !pastedEmails.includes(email));
      let newEmails = [...validEmails, ...pastedEmails];
      this.sendEmailForm.get('emails')?.setValue(newEmails);
      this.invalidEmails = await this.validateEmails(newEmails);
    }
  }

  async validateEmails(emails: string[]) {
    const invalidEmails: string[] = [];
    const seenEmails: Set<string> = new Set();
    const duplicates: Set<string> = new Set();
    for (const email of emails) {
      const trimmedEmail: string = email.replace(/,\s*$/, '').trim();
      if (!this.emailPattern.test(trimmedEmail)) {
        this.invalidEmailsMap.set(trimmedEmail, true);
        invalidEmails.push(trimmedEmail);
      }
      if (seenEmails.has(trimmedEmail)) {
        duplicates.add(trimmedEmail);
      } else {
        seenEmails.add(trimmedEmail);
      }
    }

    for (const mail of this.invalidEmailsMap.keys()) {
      if (!duplicates.has(mail) && !invalidEmails.includes(mail)) {
        this.invalidEmailsMap.delete(mail);
      }
    }
    this.duplicates = Array.from(duplicates);
    for (const duplicate of duplicates) {
      this.invalidEmailsMap.set(duplicate, true);
    }

    this.isInvalidEmails = invalidEmails.length > 0 || duplicates.size > 0;
    return invalidEmails;
  }

  isInvalidEmail(email: string): boolean {
    const trimmedEmail = email.trim();
    return this.invalidEmailsMap.has(email) || this.invalidEmailsList.includes(trimmedEmail);
  }

  removeFile(idx: number) {
    const attachments = this.sendEmailForm.get('attachment')?.value;
    attachments.splice(idx, 1);
    this.sendEmailForm.get('attachment')?.setValue(attachments);
  }

  async openLink(url: string) {
    window.open(url, '_blank');
  }

  handleRemove(event: any) {
    this.validateEmails(this.sendEmailForm.get('emails')?.value);
  }

  async handleKeydown(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.emails = this.sendEmailForm.get('emails')?.value.map((mail: any) => mail.toLowerCase())
      this.invalidEmails = await this.validateEmails(this.emails);
    }
  }

  async handleComplete(event: any) {
    this.emails = this.sendEmailForm.get('emails')?.value.map((mail: any) => mail.toLowerCase())
    this.invalidEmails = await this.validateEmails(this.emails);
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.isFileRemoved = true;
    this.uploadingFile = false;
    this.previewDataList = [];
    this.displayDataList = [];
    this.previewColumn = [];
  }

  async getAllTemplates() {
    this.group_account_id = this.accountData.group_account_id;
    const payload = {
      template_type: 'customTemplates',
      category: 'all'
    };

    const response = await this.globalService.fetchAllTemplate(payload);
    this.templatesList = response.data;
  };

  async sendMessage() {
    if (!this.validateForm()) {
      this.showWarningMessage();
      return;
    }
  
    this.loading.emit(true);
    this.clicked = true;
  
    try {
      const payload = this.buildPayload();
      const response = await this.sendMessageBasedOnType(payload);
      this.handleResponse(response);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      this.loading.emit(false);
      this.clicked = false;
    }
  }
  
  private validateForm(): boolean {

    if(this.selectedMessageType === 'email')
    {
      this.toAddress = this.selectAllStudents
        ? []
        : this.studentDataList.map((student: any) => student.primary_email);
    }
    else
    {
      this.toAddress = this.selectAllStudents
        ? []
        : this.studentDataList.map((student: any) => ({ 'user_id': student.user_id}));
    }

    const emailsControl = this.sendEmailForm.get('emails');
    if (!emailsControl?.value?.length) {
      emailsControl?.setValue(this.toAddress);
    }

    if (!this.sendEmailForm.valid && !this.selectAllStudents) {
      return false;
    }

    const content = this.sendEmailForm.get('content')?.value;
    const category = this.sendEmailForm.value.category;

    if (!content ||
      (this.selectedMessageType === 'email' && !this.campaignType) ||
      (this.selectedMessageType === 'pushNotification' && !category)) {
      return false;
    }

    return true;
  }
  
  private buildPayload(): any {
    const basePayload = {
      group_account_id: this.accountData.group_account_id,
      subject: this.sendEmailForm.value.subjectTitle,
      template_content: this.sendEmailForm.get('content')?.value,
      roundBasedEmail: this.roundBasedEmail,
      ...(this.roundBasedEmail && {driveDetails: this.driveMailInfo})
    };
  
    if (this.selectAllStudents) {
      Object.assign(basePayload, {
        filters: this.selectedFilters,
        count: this.selectAllStudentCount,
        search: this.search,
      });
    }
  
    return this.selectedMessageType === 'email'
      ? this.buildEmailPayload(basePayload)
      : this.buildNotificationPayload(basePayload);
  }
  
  private buildEmailPayload(basePayload: any): any {
    return {
      ...basePayload,
      attachments: this.sendEmailForm.value.attachment,
      campaign_type: this.campaignType,
      senderEmail: this.userData.primary_email,
      toAddress: this.sendEmailForm.value.emails || this.toAddress,
    };
  }
  
  private buildNotificationPayload(basePayload: any): any {
    return {
      ...basePayload,
      category: this.sendEmailForm.value.category,
      userData: typeof this.toAddress[0] === 'object'
      ? this.toAddress
      : this.validUserIds.map(id => ({ 'user_id': id })),
      template_content: this.stripHtmlTags(basePayload.template_content),
    };
  }

  private async sendMessageBasedOnType(payload: any): Promise<any> {
    switch (this.selectedMessageType) {
      case 'email':
        return this.globalService.sendEmail(payload);
      case 'pushNotification':
        return this.globalService.sendNotification(payload);
      case 'whatsapp':
        return this.globalService.sendWhatsappNotification(payload);
    }
  }
  
  private showWarningMessage() {
    this.messageService.add({
      severity: Severity.WARN,
      summary: Summary.WARNING,
      detail: Message.MANDATORY,
    });
  }
  
  private handleResponse(response: any) {
    if (response.success) {
      this.closeSidebar();
      this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.MESSAGESUCCESS,
      });
    } else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.MESSAGEERR,
      });
    }
  }  

  private stripHtmlTags(htmlContent: string): string {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    return div.textContent || div.innerText || "";
}

  closeSidebar() {
    this.selectAllStudentCount = 0;
    this.closesideBar.emit(false);
    this.sendemailslider = false;
    this.clicked = false;
    this.sendEmailForm.reset();
    this.sendEmailForm.get('content')?.setValue('');
    this.selectedMessageType = '';
    this.showSendEmail = false;
    this.loading.emit(false);
  }

  async onSelectExcel(event: any, type: any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = type === 'invite' ? workbook.SheetNames[1] : workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          defval: '-',
          dateNF: 'DD-MM-YYYY',
          raw: false
        });
        if (jsonData.length === 0) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Uploaded excel is empty!',
          });
          return;
        };

        let firstData: any = jsonData[0];
        if (Object.keys(firstData).length === 1 && type === 'update') {
          this.messageService.add({
            severity: 'warn',
            summary: 'Error',
            detail: 'Cannot update with single column.',
          });
          return;
        };

        this.excel = event.addedFiles;
        this.mediaFile.push(...event.addedFiles);
        this.isFileRemoved = false;
        this.handleUpload(type);

        const isValid = await this.validateAndProcessData(jsonData);
        if (!isValid) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Empty Column',
            detail: 'Empty Column names found.',
          });
          this.preview = isValid;
          this.isFileRemoved = true;
          this.mediaFile = [];
          return;
        }
        this.preview = isValid;
      } catch (error) {
        console.error('Error reading Excel file:', error);
        this.preview = false;
      }
    };
    reader.readAsBinaryString(file);
  }

  handleUpload(type: any) {
    const file = this.excel[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file);
    };
  }

  private async validateAndProcessData(jsonData: any[]): Promise<boolean> {
    if (jsonData.length) {
      const firstObject = jsonData[0];
      if (Object.keys(firstObject).includes('__EMPTY')) {
        return false;
      }
      this.patchColumn(firstObject);
    }

    this.previewDataList = jsonData;
    this.pageSize = 50;
    this.currentPreviewPage = 1;
    this.totalPreviewPage = Math.ceil(this.previewDataList.length / this.pageSize);
    this.loadCurrentPageData();

    return true;
  }

  patchColumn(column: any) {
    for (const key of Object.keys(column)) {
      this.previewColumn.push({
        field: key,
        header: key,
        freezable: false,
        sortable: true,
        tableDefault: false,
        width: '14vw',
      });
    }
    return true
  }

  loadCurrentPageData() {
    const startIndex = (this.currentPreviewPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayDataList = this.previewDataList.slice(startIndex, endIndex);
  }

  async loadTinyMce(template?: any) {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/r8fyjcvpxki8z6saolluylpci1u6mgcde4cm4rm3e1g8eiab/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    if (typeof tinymce !== 'undefined') {
      tinymce.remove();
      tinymce
        .init({
          selector: 'textarea#content',
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
          height: 500,
          borderRadius: '10px',
          menu: '',
          plugins:
            'print preview paste searchreplace autolink ' +
            'directionality code visualblocks visualchars fullscreen link media ' +
            'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
            'wordcount textpattern noneditable help charmap quickbars emoticons',
          toolbar:
            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
            'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
            'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
            'insertfile quickimage media link anchor codesample | ltr rtl',
          menubar: "",
          toolbar_sticky: true,
          images_upload_url: '',
          image_caption: true,
          quickbars_selection_toolbar:
            'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          noneditable_noneditable_class: 'mceNonEditable',
          toolbar_mode: 'wrap',
          contextmenu: 'link image imagetools table',
          relative_urls: false,
          setup: (editor: any) => {
            editor.on('Change KeyUp', () => {
              const content = editor.getContent();
              this.sendEmailForm.get('content')?.setValue(content);
            });
          }
        })
        .then((editor: any) => { });
    } else {
      console.error('TinyMCE script did not load correctly');
    }
  }

  onTemplateChange(event: any) {
    const selectedTemplateId = event.value;
    let data = this.templatesList.find((template: any) => template.id === selectedTemplateId);
    this.campaignType = data.template_title;
    this.clicked = false;
    this.sendEmailForm.controls['subjectTitle'].setValue(data.subject_title)
    this.sendEmailForm.controls['content'].setValue(data.template_content);
    tinymce.activeEditor.setContent(data.template_content);
  }

  ngOnDestroy() {
    this.sendEmailForm.reset();
  }
}