import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class DrivePlannerService {

    constructor(private http: HttpService) { }

    async getDriveDetails() {
        return await this.http.get(
            '/drives/details'
        );
    };

    async saveEvent(payload: any, event_type: string) {
        return await this.http.post(
            `/drive-planner/${event_type}`,
            payload
        );
    };

    async updateEvent(payload: any, event_type: string) {
        return await this.http.put(
            `/drive-planner/${event_type}`,
            payload
        );
    };

    async deleteEvent(payload: any, event_type: string) {
        return await this.http.post(
            `/drive-planner/delete/${event_type}`,
            payload
        );
    }

    async getEvents(payload: any, type: string) {
        return await this.http.post(
            `/drive-planner/filter/${type}`,
            payload
        );
    }
}
