import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {

  constructor(private http: HttpService) { }
  
  async fetchDepartmentProgramme(body: any) {
    const fetchDepartmentProgramme =
      await this.http.post(
        '/eligibilityCriteria/fetchDepartmentProgramme',
        body
      );
    return fetchDepartmentProgramme;
  }

  async getFilterById(body: any) {
    const getFilterById =
      await this.http.post(
        `/eligibilityCriteria/getFilterById`,
        body
      );
    return getFilterById;
  }

  async viewEligibleStudent(body: any) {
    const viewEligibileStudent =
      await this.http.post(
        `/eligibilityCriteria/getEligibleStudents`,
        body
      );
    return viewEligibileStudent;
  }

  async getAllEligibilityFilter(group_account_id: string) {
    const getAllEligibilityFilter =
      await this.http.get(
        `/eligibilityCriteria/getAllEligibilityFilter/${group_account_id}`,
      );
    return getAllEligibilityFilter;
  }

  async fetchFilterDetails(group_account_id: string) {
    const fetchfieldTemplate =
      await this.http.get(
        `/eligibilityCriteria/fetchfieldTemplate/${group_account_id}`,
      );
    return fetchfieldTemplate;
  }

  async saveFilter(body: any) {
    const saveFilter =
      await this.http.post(
        '/eligibilityCriteria/saveFilter',
        body
      );
    return saveFilter;
  }

  async saveExistingFilter(body: any) {
    const saveExistingFilter =
      await this.http.post(
        '/eligibilityCriteria/saveExistingFilter',
        body
      );
    return saveExistingFilter;
  }

  async getFilteredCandidates(body: any) {
    const getFilteredCandidates =
      await this.http.post(
        '/eligibilityCriteria/getFilteredCandidates',
        body
      );
    return getFilteredCandidates;
  }

  async getDrivesInRepublishQueue(payload: any) {
    return await this.http.post(
        '/republish/filter',
        payload
      );
  }

  async republishDrive(drive_id: string) {
    const republishDrive =
      await this.http.post(
        `/republish/${drive_id}`,
        {}
      );
    return republishDrive;
  }
}
